import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Filter from "../components/Filter";
import { useFormik } from "formik";
import Products from "../components/Products";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Banners from "../components/Banners";
import { useParams } from "react-router-dom";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";

const CategoryPage = () => {
  const { category } = useParams();
  const [data, setData] = useState();
  const [products, setproducts] = useState();
  const [loading, setloading] = useState(true);

  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/category", {}, {});
    const finded = res.data?.data?.find((item) => item.name === category);
    const res2 = await httpReauest(
      "GET",
      "/product?category=" + finded?._id,
      {},
      {}
    );
    setData(finded);
    setproducts(res2.data.data);
    setloading(false);
  }

  useEffect(() => {
    getData();
  }, [category]);

  const formik = useFormik({
    initialValues: {
      min_price: "",
      max_price: "",
      category: "",
      haveImg: "",
      onFire: "",
      search_key: "",
      search_type: "product",
    },
  });

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.meta_title}</title>
        <meta name="description" content={data?.meta_desc} />
        <meta name="og:description" content={data?.meta_desc} />
        <meta name="og:title" content={data?.meta_title} />
        <script type="application/ld+json">{data?.faq}</script>
      </Helmet>
      <Header />

      <div
        dir="rtl"
        className="grid lg:grid-cols-4 gap-8 w-full py-8 px-[10px] lg:px-10 xl:px-12 bg-white text-right"
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="lg:col-span-1">
              <Filter formik={formik} />
            </div>
            <div className="lg:col-span-3">
              <Products data={products} formik={formik} category={data?._id} />
            </div>
          </>
        )}
      </div>
      <Banners />
      <Footer />
    </div>
  );
};

export default CategoryPage;
