import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Banners = () => {
  const banners = [
    "/banner/1 (1).jpg",
    "/banner/1 (2).jpg",
  ];
  return (
    <div>
      <Swiper modules={[Autoplay]} autoplay={{ delay: 2500 }}>
        {banners.map((item) => (
          <SwiperSlide>
            <img alt="دکتر گلد بنر" src={item} className="w-full object-cover " />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banners;
