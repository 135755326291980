import React, { useEffect, useState } from "react";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";
import CourseCard from "./common/CourseCard";
import { FaSquare } from "react-icons/fa";

const CoursesSection = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getData() {
    setLoading(true);
    const res = await httpReauest("GET", "/course", {}, {});
    setData(res?.data?.data);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div dir="rtl" className=" bg-white py-8 text-right lg:px-16 ">
      <div className="relative  z-10 pb-8 lg:pt-10 flex-col items-center justify-center flex">
        <div className="text-center font-bold justify-center flex gap-2 items-center  text-[24px] lg:text-[36px] ">
          <FaSquare size={12} color="#F37324" />
          <h1>دوره ها</h1>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 px-3 sm:px-2 lg:px-1">
          {loading ? (
            <Loading />
          ) : (
            data?.map((item) => <CourseCard data={item} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursesSection;
