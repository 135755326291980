import React from "react";
import { CDN_BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { priceComma } from "../../utils/priceComma";

const CourseCard = ({ data }) => {
  return (
    <Link
      to={"/courses/" + data?._id}
      class="max-w-sm bg-white border border-gray-200 rounded-lg shadow h-fit"
    >
      <img
        class="rounded-t-lg"
        src={CDN_BASE_URL + data?.img}
        alt={data?.name}
      />
      <div class="p-3">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
          {data?.name}
        </h5>
        <div className="mb-2 flex  flex-col justify-start items-start">
          <span className="font-bold text-black/70 text-[16px] ">
            {priceComma(data?.price, 2)} تومان
          </span>
          {/* <span className="font-bold text-red-500 text-[18px] ">
            {priceComma(data?.special_price, 2)} تومان
          </span> */}
        </div>
        <p class="mb-3 font-normal text-gray-700 "> {data?.desc}</p>
        <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#e09145] rounded-lg hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-orange-300">
          اطلاعات بیشتر
          <svg
            class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </div>
    </Link>
  );
};

export default CourseCard;
