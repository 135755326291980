import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import SliderVideo from "../components/SliderVideo";
import Footer from "../components/Footer";
import BestProducts from "../components/BestProducts";
import NewProducts from "../components/NewProducts";
import { Helmet } from "react-helmet";
import Banners from "../components/Banners";
import Banners2 from "../components/Banners2";
import CoursesSection from "../components/CoursesSection";

const Homepage = () => {
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"خرید آنلاین زیورآلات و طلا با کیفیت | دکتر گلد"}</title>
        <meta
          name="description"
          content="جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
        />
        <meta
          name="og:description"
          content="جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
        />
        <meta
          name="og:title"
          content={"خرید آنلاین زیورآلات و طلا با کیفیت | دکتر گلد"}
        />
      </Helmet>
      <Header />
      <SliderVideo />
      <div className="bg">
        <AboutSection home />
        <BestProducts />
        <CoursesSection />
        <Banners />
        <TestimonialSection />
        <NewProducts />
        <Banners2 />
        <WhySection />
      </div>
      <ContactUs />
      {/* <InfoSection /> */}
      <Footer />
    </div>
  );
};

export default Homepage;
