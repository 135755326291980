import React from "react";
import Reveal from "./motion/Reveal";
import { Link } from "react-router-dom";
import RevealX from "./motion/RevealX";

const AboutSection = ({ home }) => {
  return (
    <section
      style={{ backgroundImage: "url(/bg.png)" }}
      class="about_section  bg-right lg:bg-center bg-cover"
    >
      <div class="container-fluid bg-black/50  py-10 lg:py-16">
        <div class="row text-right ">
          <div class="col-md-4 offset-md-2">
            <div class="about_detail-box text-white">
              <Reveal>
                <h3 class="custom_heading text-[2rem]">شروع دکتر گلد</h3>
                <h4 className="mt-2 font-bold opacity-85">
                  {" "}
                  پرورش کارآفرینان طلایی کشور با دکتر گلد 
                </h4>
              </Reveal>
              <Reveal>
                {home ? (
                  <p class="">
                    من سعید امیری هستم موسس برند دکتر گلد از سال ۱۳۸۹ وارد
                    حیطه‌ی طلا و جواهر شدم و دکتر گلد برندی بود که تاسیس شد. در
                    این مسیر که از مشاوره‌های کسب‌وکار، آموزش فروشندگی طلا
                    جواهرات و رشد کسب و کار در صنعت طلا جواهرات شروع شد و تا به
                    الان که فروش محصولات خاص و سفارشی سازه شده برای شما عزیزان
                    خاص پسند به دست هنرمندان خوش ذوق و برجسته کشور عزیزمون محیا
                    شده، مفتخریم که در کنار شما هستیم.
                  </p>
                ) : (
                  <p class="">
                    من سعید امیری هستم موسس برند دکتر گلد از سال ۱۳۸۹ وارد
                    حیطه‌ی طلا و جواهر شدم و دکتر گلد برندی بود که تاسیس شد. در
                    این مسیر که از مشاوره‌های کسب‌وکار، آموزش فروشندگی طلا
                    جواهرات و رشد کسب و کار در صنعت طلا جواهرات شروع شد و تا به
                    الان که فروش محصولات خاص و سفارشی سازه شده برای شما عزیزان
                    خاص پسند به دست هنرمندان خوش ذوق و برجسته کشور عزیزمون محیا
                    شده، مفتخریم که در کنار شما هستیم. همیشه برای من رضایت مشتری
                    اولویت داشته و همین باعث رشد برند دکترگلد شد تا رفته رفته
                    تیم دکتر گلد بزرگ تر شد و یکی از افتخارات هولدینگ دکتر گلد،
                    مشتریان ثابت و وفاداری است که همراه خود دارد. پشتیبانی قوی،
                    محصولات خاص و تک و گارانتی محصولات نشان از ارزش و رسالت
                    مجموعه دکتر گلد می باشد. افتخار میکنم که علاوه بر طراحی و
                    ساخت سفارشی محصولات طلا، هولدینگ دکتر گلد اولین و تنها
                    مجموعه ای است مرجع در صنعت طلاجواهرات و صفر تا صد آموزش
                    فروشندگی،رشد کسب و کار، راه‌اندازی، ابزارآلات و... نیز در بر
                    دارد.
                  </p>
                )}
              </Reveal>
              {home && (
                <Reveal>
                  <div>
                    <Link className="rounded-[8px]" to="/about">بیشتر</Link>
                  </div>
                </Reveal>
              )}
            </div>
          </div>
          <div class="col-md-4">
            <RevealX>
              <div class="about_img-box mt-3 sm:mt-0">
                <img src="images/about (2).png" alt="" />
              </div>
            </RevealX>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
