import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import RevealX from "./motion/RevealX";
import Reveal from "./motion/Reveal";
import { CDN_BASE_URL } from "../config";
import ReactPlayer from "react-player/lazy";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const videos = [
  "uploads/testmonials (1).mp4",
  "uploads/testmonials (2).mp4",
  "uploads/testmonials (3).mp4",
  "uploads/testmonials (4).mp4",
];

const TestimonialSection = () => {
  const swiperRef = useRef(null);
  const [canPrev, setCanPrev] = useState(true);
  const [canNext, setCanNext] = useState(true);

  const handleSlideChange = (swiper) => {
    setCanPrev(swiper.isBeginning);
    setCanNext(swiper.isEnd);
  };

  return (
    <section className="client_section layout_padding-bottom relative bg-white py-4">
      <div className="container text-right">
        <RevealX>
          <h3 className="custom_heading text-[1.75rem]">
            از انتخاب دکترگلد پشیمان نمیشوید
          </h3>
        </RevealX>
        <Reveal>
          <p className="mt-1">نظرات کاربران در مورد دکتر گلد</p>
        </Reveal>
      </div>
      <div className="container mx-auto my-[2rem] md:px-16 relative">
        <Reveal>
          <Swiper
            ref={swiperRef}
            dir="ltr"
            spaceBetween={40}
            breakpoints={{
              320: { slidesPerView: 1 },
              650: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            // onSlideChange={handleSlideChange}
            allowTouchMove={false}
          >
            {videos.map((item, index) => (
              <SwiperSlide key={index}>
                <ReactPlayer
                  url={CDN_BASE_URL + item}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Reveal>
        <button
          className={`absolute text-[36px] lg:text-[42px] left-0 lg:-left-6 focus:ring-4 focus:outline-none focus:ring-orange-300 z-20 top-1/2 transform -translate-y-1/2 bg-[#e09145] text-white p-1 rounded-full transition ${
            !canPrev ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => canPrev && swiperRef.current.swiper.slidePrev()}
        >
          <FaAngleLeft />
        </button>
        <button
          className={`absolute text-[36px] lg:text-[42px] right-0 lg:-right-6 focus:ring-4 focus:outline-none focus:ring-orange-300 z-20 top-1/2 transform -translate-y-1/2 bg-[#e09145] text-white p-1 rounded-full transition ${
            !canNext ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => canNext && swiperRef.current.swiper.slideNext()}
        >
          <FaAngleRight/>
        </button>
      </div>
    </section>
  );
};

export default TestimonialSection;
