import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaCopy, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { httpReauest } from "../utils/httpRequest";
import { BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";
import { calculateGoldPrice } from "../utils/getPrice";
import { CgCopy } from "react-icons/cg";
import { BiCopy } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import copy from "clipboard-copy";
const CourseForm = ({ packing }) => {
  const [payinfo, setpayinfo] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [img, setImg] = useState();
  const { id } = useParams();

  const navigate = useNavigate();

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    const res = await httpReauest("GET", "/data/info", {}, {});
    setpayinfo(res.data.data);
  }

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
    },
    onSubmit: async (values) => {
      if (!img) {
        return toast.error("تصویر چک پرداختی خالی است");
      }

      const keys = Object.keys(values);
      const formdata = new FormData();

      keys.map((item) => formdata.append(item, values[item]));
      formdata.append("payment", img, img.name);
      formdata.append("course", id);

      await fetch(BASE_URL + "/course", {
        method: "POST",
        body: formdata,
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("ثبت نام شما با موفقیت ثبت شد");
          navigate("/courses");
        } else {
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      dir="rtl"
      class=" bg-gray-50 lg:px-4 px-2 lg:pt-4 pt-2 lg:mt-0"
    >
      <p class="text-xl font-medium">اطلاعات برای ثبت نام</p>
      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-3 mb-2 block text-sm font-medium">
              نام و نام خانوادگی
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="phone" class="mt-3 mb-2 block text-sm font-medium">
              شماره تماس
            </label>
            <div class="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-3 mb-2 block text-sm font-medium">
              آپلود فیش واریز
            </label>{" "}
            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  size={30}
                />
              )}
            </label>
          </span>
          <span>
            <label for="email" class="mt-3 mb-2 block text-sm font-medium">
              شماره کارت واریزی
            </label>{" "}
            <div dir="ltr" class="relative">
              <input
                type="text"
                value={payinfo?.card_number}
                disabled
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
              <span
                onClick={() => copy(payinfo?.card_number)}
                className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
              >
                <BiCopy size={20} />
              </span>
            </div>
            <span className="mt-4  text-sm font-medium">
              {" "}
              {payinfo?.card_name}
            </span>
          </span>
          <span className="col-span-full">
            <p>
              بعد از تکمیل فرم و آپلود فیش واریزی در صورتی که اطلاعات درست باشند
              همکاران ما با شما ارتباط خواهند گرفت و لینک های دوره را در اختیار
              شما میگذارند
            </p>
          </span>

          <input
            onChange={(e) => {
              handleImg(e);
            }}
            type="file"
            className="hidden"
            id="img"
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
      </div>
      <button
        type="submit"
        class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
      >
        ثبت نام
      </button>
    </form>
  );
};

export default CourseForm;
