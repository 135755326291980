import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FaArrowDown,
  FaArrowLeft,
  FaCartPlus,
  FaCircle,
  FaFileCode,
  FaSquare,
  FaThermometerEmpty,
  FaUser,
  FaWallet,
} from "react-icons/fa";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import CourseCard from "../components/common/CourseCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import { useParams } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import { priceComma } from "../utils/priceComma";
import CourseForm from "../components/CourseForm";
import { toast } from "react-toastify";

const CoursesDetailPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [code, setcode] = useState();
  const [discounted, setdiscounted] = useState();
  const { id } = useParams();

  async function getData() {
    setLoading(true);
    const res = await httpReauest("GET", "/course/" + id, {}, {});
    setData(res?.data?.data);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  async function handleDiscount() {
    await httpReauest("POST", "/data/discounted/auth", { code }, {})
      .then((res) => {
        if (res.status === 201) {
          toast.success("کد تخفیف صحیح است");
          setdiscounted(true);
        } else {
          toast.error("کد تخفیف اشتباه است");
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | " + data?.name}</title>
        <meta name="description" content={data?.desc} />
        <meta name="og:description" content={data?.desc} />
        <meta name="og:title" content={"دکتر گلد | " + data?.name} />
      </Helmet>
      <Header />
      <div dir="rtl" className=" bg-white ">
        <div className="bg-white py-8 text-right lg:px-16 container">
          <div className=" px-3 sm:px-2 lg:px-1 font-bold justify-center flex gap-2 items-start lg:items-center text-[18px] lg:text-[32px] ">
            <FaSquare size={12} color="#F37324" className="mt-1" />
            <h1>{data?.name}</h1>
          </div>
          <div className=" mt-4 ">
            <video
              src={CDN_BASE_URL + data?.video}
              className="w-full "
              controls
            />
          </div>
          <div class="my-4 " style={{ textAlign: "right" }}>
            {data?.desc}
          </div>
          <div dangerouslySetInnerHTML={{ __html: data?.more_desc }} />
          <div className="mt-2 pt-2 border-t-2 text-[20px] font-bold grid grid-cols-2">
            <div>
              قیمت آفلاین :{" "}
              {discounted ? (
                <div className="mb-2 flex  flex-col justify-start items-start">
                  <span className="font-bold text-black/70 text-[16px] lg:text-[20px] discounted">
                    {priceComma(data?.price, 2)} تومان
                  </span>
                  <span className="font-bold text-red-500 text-[18px] lg:text-[24px] ">
                    {priceComma(data?.special_price, 2)} تومان
                  </span>
                </div>
              ) : (
                <div className="mb-2 flex  flex-col justify-start items-start">
                  <span className="font-bold text-black/70 text-[16px] lg:text-[20px]">
                    {priceComma(data?.price, 2)} تومان
                  </span>
                </div>
              )}
              <div className="flex gap-2 items-end mb-4">
                <span>
                  <label for="email" class="block font-medium">
                    کد تخفیف
                  </label>
                  <input
                    type="text"
                    id="discounted"
                    onChange={(e) => setcode(e.target.value)}
                    value={code}
                    class="w-full font-thin rounded-md border border-gray-200 px-3 py-2 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  />
                </span>
                <button
                  onClick={() => handleDiscount()}
                  type="button"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#e09145] rounded-lg hover:bg-orange-500 focus:ring-4 focus:outline-none"
                >
                  تایید
                </button>
              </div>
            </div>
            {data?.price_person && (
              <div>
                {discounted ? (
                  <>
                    قیمت حضوری :{" "}
                    <div className="mb-2 flex  flex-col justify-start items-start">
                      <span className="font-bold text-black/70 text-[16px] lg:text-[20px] discounted">
                        {priceComma(data?.price_person, 2)} تومان
                      </span>
                      <span className="font-bold text-red-500 text-[18px] lg:text-[24px] ">
                        {priceComma(data?.special_price_person, 2)} تومان
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    قیمت حضوری :{" "}
                    <div className="mb-2 flex  flex-col justify-start items-start">
                      <span className="font-bold text-black/70 text-[16px] lg:text-[20px]">
                        {priceComma(data?.price_person, 2)} تومان
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <CourseForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoursesDetailPage;
