import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Banners2 = () => {
  const banners = [
    "/banner/1 (3).jpg",
  ];
  return (
    <div>
      <Swiper modules={[Autoplay]} autoplay={{ delay: 2500 }}>
        {banners.map((item) => (
          <SwiperSlide>
            <img alt="دکتر گلد بنر" src={item} className="w-full object-cover h-[200px] md:h-[280px] lg:h-[300px] xl:h-[340px] 2xl:h-[380px]" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banners2;
