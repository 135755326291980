import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FaArrowDown,
  FaArrowLeft,
  FaCartPlus,
  FaCircle,
  FaFileCode,
  FaSquare,
  FaThermometerEmpty,
  FaUser,
  FaWallet,
} from "react-icons/fa";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import CourseCard from "../components/common/CourseCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import CoursesSection from "../components/CoursesSection";

const CoursesPage = () => {
  
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | دوره های طلا فروشی"}</title>
        <meta
          name="description"
          content={
            "به دنیای جذاب و پرچالش فروش طلا خوش آمدید! در این دوره‌ها، شما با تکنیک‌ها و استراتژی‌های مؤثر در فروش طلا آشنا خواهید شد که به شما کمک می‌کند تا به یک فروشنده موفق تبدیل شوید."
          }
        />
        <meta
          name="og:description"
          content={
            "به دنیای جذاب و پرچالش فروش طلا خوش آمدید! در این دوره‌ها، شما با تکنیک‌ها و استراتژی‌های مؤثر در فروش طلا آشنا خواهید شد که به شما کمک می‌کند تا به یک فروشنده موفق تبدیل شوید."
          }
        />
        <meta name="og:title" content={"دکتر گلد | دوره های طلا فروشی"} />
      </Helmet>
      <Header />
      <CoursesSection />
      <Footer />
    </div>
  );
};

export default CoursesPage;
